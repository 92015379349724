// Load Styles
import "../scss/main.scss";

//eigene versionen
// import './shared/htmx.js'
import "./shared/menu.js";
import "./shared/cookiebar.js";
import "./shared/scrolltotop.js";

// Your app code
console.log(`Hello ${process.env.HELLO}`);
