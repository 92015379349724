var scrollToTopBtn = document.getElementById("scrollToTopBtn");
var rootElement = document.documentElement;
var TOGGLE_RATIO = 0.8;

// When the user scrolls down 20px from the top of the document, show the button
// window. ist wichtig sonst kennt der Browser die Funktion nicht!
window.onscroll = function () {
  handleScroll();
};
window.topFunction = function () {
  topFunction();
};

function handleScroll() {
  // do something on scroll
  var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
  if (rootElement.scrollTop / scrollTotal > TOGGLE_RATIO) {
    //show button
    scrollToTopBtn.style.display = "block"; //classList.add("showBtn")
  } else {
    //hide button
    scrollToTopBtn.style.display = "none"; //classList.remove("showBtn")
  }
}
// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
